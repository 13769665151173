














































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  AttachmentHostType,
  WarehouseInStorageDetailDto,
  WarehouseInStorageMainDto,
} from "@/api/appService";
import api from "@/api";
import AttachmentsView from "@/components/AttachmentsView.vue";

@Component({
  name: "serviceObjectDetail",
  components: { AttachmentsView },
})
export default class ServiceObjectDetail extends Vue {
  inStorageMainId?: number;
  detail: WarehouseInStorageMainDto = {};
  detailList: WarehouseInStorageDetailDto[] | undefined = [];
  loading = true;
  hostType = AttachmentHostType.Warehousing;

  created() {
    if (this.$route.params.id) {
      this.inStorageMainId = Number(this.$route.params.id);
      this.fetchDetail();
    }
  }

  // 获取详情
  fetchDetail() {
    api.warehouseInStorageMain.get({ id: this.inStorageMainId }).then((res) => {
      this.loading = false;
      this.detail = { ...res };
      this.detailList = res!.detailList;
    });
  }

  isImage(item: any) {
    if (!item.extension) {
      return false;
    }
    let ext = "";
    if (item.extension.substr(0, 1) === ".") ext = item.extension.substr(1);

    return ["jpg", "jpeg", "png", "gif", "bmp"].includes(ext.toLowerCase());
  }

  //下载附件
  handleDownload(item: any) {
    // api.attachment.download(item.id);
    if (this.isImage(item)) {
      window.open(item.url, "_blank");
    } else {
      const downloadUrl = `${this.baseURL}/api/services/app/Attachment/Download?id=${item.id}`;
      window.open(downloadUrl, "_blank");
    }
  }
}
