

















































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AttachmentDto, AttachmentHostType } from "@/api/appService";
import api from "@/api";

@Component({
  name: "AttachmentsView",
})
export default class AttachmentsView extends Vue {
  @Prop({ required: true })
  hostId!: number | string;

  @Prop({ required: true })
  hostType!: AttachmentHostType;

  attachments: AttachmentDto[] = [];

  loading = false;

  @Watch("hostId")
  async onHostIdChange() {
    await this.loadAttachments();
  }

  @Watch("hostType")
  async onHostTypeChange() {
    await this.loadAttachments();
  }

  async created() {
    await this.loadAttachments();
  }

  async loadAttachments() {
    this.loading = true;
    await api.attachmentService
      .getAttachments({
        hostId: this.hostId.toString(),
        hostType: this.hostType,
      })
      .then((res) => {
        this.loading = false;
        this.attachments = res;
      });
  }

  handleDownload(item: AttachmentDto) {
    // api.attachment.download(item.id);
    if (this.isImage(item)) {
      window.open(item.url, "_blank");
    } else {
      const downloadUrl = `${this.baseURL}/api/services/app/Attachment/Download?id=${item.id}`;
      window.open(downloadUrl, "_blank");
    }
  }

  isImage(item: AttachmentDto) {
    if (!item.extension) {
      return false;
    }
    let ext = "";
    if (item.extension.substr(0, 1) === ".") ext = item.extension.substr(1);

    return ["jpg", "jpeg", "png", "gif", "bmp"].includes(ext.toLowerCase());
  }
}
